import React from "react";
import { StaticImage } from "gatsby-plugin-image";


import "./styles.scss";

function OverView() {
	

  return (
    <React.Fragment>
      <div className="hiring-main-overview">
        <div className="placements-container">


          <div className="recruiting-partner">
            <div className="mlp-student-slider">
              <h2 className="slider-section-head">Trusted by the world's leading <br/>marketing companies</h2>
              <span>
                <StaticImage 
                  src="https://kfwebassets.s3.ap-south-1.amazonaws.com/common/Group-11278.png"
                  alt="title underlines"
                  placeholder="none"
                />
              </span>
			
            </div>
            <div className="recruiter-img">
              <StaticImage
                src="../../../images/khimages/kh-image1-dsk.png"
                alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
                title="Recruiting partners"
                placeholder="none"
              />
            </div>
                		<div className="recruit-img">
              <StaticImage
                src="../../../images/khimages/kh-img2-dsk.png"
                alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
                title="Recruiting partners"
                placeholder="none"
              />
            </div>
            <div className="recruiter-img-mb">
              <StaticImage
                src="../../../images/khimages/kh-mob-img.png"
                alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
                title="Recruiting partners"
                placeholder="none"
              />
            </div>


          </div>



				
        </div>
      </div>

	
    </React.Fragment>
  );
}

export default OverView;
