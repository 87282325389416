import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import Card2 from "../../TestimonialCard/Card2/index";
import { placementsData } from "./data";

import "./styles.scss";

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  
  return (
	  <div
      className={className + " yt-arrow-prev"}
      aria-hidden="true"
      onClick={onClick}
	  >
      <img
		  src="https://kfwebassets.s3.ap-south-1.amazonaws.com/mlp_images/Subtract-2.png"
		  alt="prev"
		  width="30"
		  height="48"
      />
	  </div>
  );
}
  
function SampleNextArrow(props) {
  const { className, onClick } = props;
  
  return (
	  <div
      className={className + " yt-arrow-next"}
      aria-hidden="true"
      onClick={onClick}
	  >
      <img
		  src="https://kfwebassets.s3.ap-south-1.amazonaws.com/mlp_images/Subtract-3.png"
		  alt="next"
		  width="30"
		  height="48"
      />
	  </div>
  );
}

function Placements() {
  const settings = {
    prevArrow: "",
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: true,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          slidesToShow: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  const scrollTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top:0,
        behavior: "smooth"
      });
    }
  };

  return (
    <React.Fragment>
      <div className="hiring-page-main-placements">
        <div className="placements-container">
          <h2 className="placements-head">Here's what our <br/> hiring partners have to say</h2>
          <span className="strokes">
            <StaticImage
              src="../../../images/mlp/yellow-underline.webp"
              alt="underline"
              className=""
            />
          </span>
          <div className="placement-slider">
            <Slider {...settings}>
              {placementsData.map((info, key) => (
                <Card2
                  key={key}
                  image={info.image}
                  name={info.name}
                  company={info.company}
                  description={info.description}
                  extraText={info.extra_text ? info.extra_text : ""}
                />
              ))}
            </Slider>
          </div>
          			<div className="recruit-img">
		  					<a onClick={scrollTop}>
              <StaticImage
                src="../../../images/khimages/kh-click-img-desk.png"
                alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
                title="Recruiting partners"
                placeholder="none"
								
              />
            </a>
          </div>
            			<div className="recruiter-img-mb">
            <a onClick={scrollTop}>
              <StaticImage
                src="../../../images/khimages/kh-click-img-mb.png"
                alt="Digital Marketing Recruiting Partners for The Marketing Launchpad"
                title="Recruiting partners"
                placeholder="none"
								
              />
            </a>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default Placements;
